@import "~bootstrap/scss/bootstrap";

.App {
  text-align: center;
}

.noselect {
  user-select: none;
}

.app-content {
  overflow:hidden; 
  height: 100%;
}
