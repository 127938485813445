.appsettings {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: auto;
  z-index: 3;
}

.settingshivecontainer {
  position: relative;
}

.homecontainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.homecontent {
  flex-grow: 1;
  overflow: auto;
}

.homefooter {
  flex-shrink: 0;
}


.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 240px;
}
.spinner {
  height: 175px;
  width: 175px;
}