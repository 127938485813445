$blackPercent: 10%;
$cellHeight: 80px;

.center {
  fill: #ffc107;
}

.center.active {
  fill: mix(black, #ffc107, $blackPercent);
}

.outer {
  fill: lightgrey;
}

.outer.active {
  fill: mix(black, lightgrey, $blackPercent);
}

svg text {
  pointer-events: none;
  user-select: none;
}

.hive-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc($cellHeight*3);
  position: relative;
}
.hive {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: calc($cellHeight*3);
}

.hive-cell {
  position: absolute;
  height: $cellHeight;
  top: 50%;
  left: 50%;
}

.hive-cell:nth-child(1) {
  transform: translate(-50%, -50%);
}
.hive-cell:nth-child(2) {
  transform: translate(-125%, -100%);
}
.hive-cell:nth-child(3) {
  transform: translate(-50%, -150%);
}
.hive-cell:nth-child(4) {
  transform: translate(25%, -100%);
}
.hive-cell:nth-child(5) {
  transform: translate(25%, 0%);
}
.hive-cell:nth-child(6) {
  transform: translate(-50%, 50%);
}
.hive-cell:nth-child(7) {
  transform: translate(-125%, 0%);
}

.active {
  transform: scale(0.9);
  transform-origin: 150px 130px;
  transition: all 100ms;
}

.letter {
  text-anchor: middle;
  font-size: 10em;
  font-weight: bold;
  fill: black;
}
