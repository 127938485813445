.amplify-button[data-variation='primary'] {
    color: rgba(0,0,0,0.75);
    background: rgba(255, 193, 7, 1.0);
    border-color: var(--bs-warning)
}

button:hover.amplify-button[data-variation='primary'] {
    border-color: black;
}

.amplify-button[data-variation='link'] {
    color: rgba(0,0,0,0.75);
}
button:hover.amplify-button[data-variation='link'] {
    color: rgba(0,0,0,0.75);
    background-color: rgba(255, 193, 7, 0.25);
    border-color: var(--bs-warning);
}

button:hover.federated-sign-in-button {
    background-color: rgba(255, 193, 7, 0.25);
    border-color: var(--bs-warning);
}

.amplify-tabs-item[data-state='active']{
    border-color: orange;
}