.alert-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .height-grow {
    flex-grow: 1;
    margin: 0;
  }
  
  .height-default {
    flex-grow: 0;
  }
  
  .error-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  
  html,
  body,
  #root,
  .alert-component {
    width: 100%;
    height: 100%;
  }
  