.vertical-container {
  position: relative;
  height: 180px;
}

.rangetitle {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateX(34%) translateY(-50%) rotateZ(270deg);
}

.vertical-range {
  display: flex;
  position: absolute;
  flex-direction: row;
  height: 24px;
  width: 180px;
  right: 0;
  top: 50%;
  justify-content: center;
  transform: translateX(34%) translateY(-50%) rotateZ(270deg);
}

.vertical-range input[type="range"] {
  width: 100%;
  height: 100%;
}
