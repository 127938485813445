[tabindex] {
  outline: none;
}

.divinput {
  height: 2em;
}

.focusshadow {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
}

.posrel {
  position: relative;
}

.posright {
  position: absolute;
  right: 0;
}

.my-text-center {
  display: inline-block;
}