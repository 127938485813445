.checkoutcontainer {
  width: 100%;
  height: 100%;
}

/* CSS styles for screens 1000px or more */
@media (min-width: 1000px) {
  .product,
  .stripediv {
    display: inline-block;
    vertical-align: top;
    width: 50%; /* Leave a little room for padding/margins */
    height: 100%;
  }

  div.img-container > img {
    max-width: 300px;
  }
}

/* CSS styles for screens less than 1000px */
@media (max-width: 999px) {
  .product,
  .stripediv {
    display: block;
    width: 100%;
  }

  div.img-container > img {
    max-width: 200px;
  }
}

div.input-group {
  max-width: 320px;
}

.img-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stripe-inner-container {
  display: flex;
  align-items: center;
}

.stripe-outer-container {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
}

#payment-form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

#payment-form #payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-form #payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
#payment-form button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

#payment-form button:hover {
  filter: contrast(115%);
}

#payment-form button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
#payment-form .spinner,
#payment-form .spinner:before,
#payment-form .spinner:after {
  border-radius: 50%;
}

#payment-form .spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

#payment-form .spinner:before,
#payment-form .spinner:after {
  position: absolute;
  content: "";
}

#payment-form .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

#payment-form .spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  #payment-form {
    width: 95vw;
    min-width: 0; // remove minimum width on small screens
  }
}
